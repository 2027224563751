import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "from-angular-to-react---how-i-decided-to-take-the-next-career-step"
    }}>{`From Angular to React - how I decided to take the next career step`}</h1>
    <h5 {...{
      "id": "february-10-2022--js-world-online-conference"
    }}>{`February 10, 2022 | `}<a parentName="h5" {...{
        "href": "https://frontenddeveloperlove.com/free"
      }}>{`JS World`}</a>{` online conference`}</h5>
    <hr></hr>
    <ul>
      <li parentName="ul">{`⌚ `}<strong parentName="li">{`Duration`}</strong>{`: ~15 minutes`}</li>
      <li parentName="ul">{`👨‍💻 `}<strong parentName="li">{`Form`}</strong>{`: presentation`}</li>
    </ul>
    <hr></hr>
    <p>{`There's a time in every developer's career when they ask themselves a question - "What's the next big thing for me?". There are a lot of reasons for it. We do that to improve our skills, our paychecks, to start doing things that we care about.`}</p>
    <p>{`Recently I asked this question myself and decided to gain a new perspective as a web developer - to learn the next major technology there is. After two years of working in Angular, I decided to switch to React.`}</p>
    <p>{`This talk sums up the reasons behind this change and what's my opinion about those two web giants after six months after the change.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      